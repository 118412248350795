/* Reset default margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure the body takes full height */
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

/* Ensure the root div takes full height */
#root {
  min-height: 100vh;
}

.landingPageOval {
    background-image: url("../../assets/images/Oval.png");
    height: 260px;
    width: 480px;
    position: absolute;
    right: 0;
    background-size: cover;
    opacity: 0.7;
    animation: float 6s ease-in-out infinite;
}

.landingPageContainer {
    height: 100vh;
    /* overflow: hidden; */
}

.landingPageMidSection {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(10%, -50%);
}

.links-navigate {
    text-decoration: none;
    color: #fff;
}

.links-navigate:hover {
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 1060px) {
    .landingPageOval {
        height: 400px;
        width: 500px;
    }
}

@media (max-width: 740px) {
    .landingPageOval {
        height: 300px;
        width: 400px;
    }

    .contactUsButton {
        width: 200px;
    }
}

@media screen and (max-width: 650px) {

    .yourLoadsWith {
        font-size: 32px;
    }

    .yourLoadsWith span {
        font-size: 32px;
        white-space: revert;
    }

    .content {
        font-size: 16px;
        line-height: 40px;
        white-space: inherit;
    }

    .landingPageMidSection {
        width: 80%;
    }
}

/* Mystical animations */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(0, 145, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 145, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 145, 255, 0.2);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation classes */
.floating {
  animation: float 3s ease-in-out infinite;
}

.glow-effect {
  animation: glow 2s ease-in-out infinite;
}

.fade-in {
  animation: fadeIn 0.8s ease-out forwards;
}